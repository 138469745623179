import {
  gddRangeToMsg,
  calculateGdd,
  baskervilleEmin,
  ddHrIntegrated,
} from "../../utils/helpers"
import { format } from "date-fns"

export default function GrapeBerryMothLogic(
  modelData,
  data,
  dateOfInterest,
  isModelInSeason,
  userBiofix1 = null
) {
  // console.count("ModelLogic------------------------")
  const {
    baseBeforeBiofix,
    baseAfterBiofix,
    elements,
    baseFormulaBeforeBiofix1,
    dashboardDisplayBeforeBiofix1,
    dashboardDisplayAfterBiofix1,
  } = modelData
  const { userInputs } = elements
  const { beforeBiofix1, afterBiofix1 } = elements.managementGuide

  const dailyDataWithGdd = calculateGdd(
    data.dailyData,
    baseBeforeBiofix,
    0,
    baskervilleEmin
  ).map((d) => {
    return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
  })

  // console.log(userBiofix1, userBiofix2)
  // User Biofix dates -------------------------------------------------------
  let userBiofix1Index = 0
  if (userBiofix1 !== null) {
    userBiofix1Index = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userBiofix1
    )
  }

  // Recalculating degree-days when biofix1 ----------------------------------
  let gdd1 = 0
  let indices = []
  const mData = dailyDataWithGdd.map((day, i) => {
    let p = {
      date: day.date,
      ms: day.ms,
      dateDisplay: day.dateDisplay,
      dayOfYear: day.dayOfYear,
      isForecast: day.isForecast,
      [`${baseFormulaBeforeBiofix1}dd`]: day.dd,
      [`${baseFormulaBeforeBiofix1}Gdd`]: day.gdd,
      displayBiofix1Input:
        day.gdd >= userInputs.firstInput.displayElement ? true : false,
      biofix1Gdd: "N/A",
      biofix1dd: "N/A",
      temp: day.temp,
    }

    // Recalculate if user has selected a biofix1 date
    const dd1 = ddHrIntegrated(day.temp, baseAfterBiofix)
    if (userBiofix1 !== null) {
      if (i >= userBiofix1Index) {
        indices.push(i)
        gdd1 += dd1 === "N/A" ? 0 : dd1
        p.biofix1dd = Math.round(dd1)
        p.biofix1Gdd = Math.round(gdd1)
      }
    } else {
      if (day.gdd >= userInputs.firstInput.biofix1) {
        indices.push(i)
        gdd1 += dd1 === "N/A" ? 0 : dd1
        p.biofix1dd = Math.round(dd1)
        p.biofix1Gdd = Math.round(gdd1)
      }
    }
    return p
  })
  ////////////////////////////////////////////////////////////////////////////////////

  let selectedDate = mData.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = mData.find((d) => d.isForecast)
  }
  // console.log(selectedDate)

  // Biofix Dates ----------------------------------------------------------
  let biofix1 = null
  if (indices.length !== 0) {
    biofix1 = mData[indices[0]]
  }

  if (biofix1) {
    if (selectedDate.dayOfYear < biofix1.dayOfYear) biofix1 = null
  }
  // console.log(biofix1)
  ////////////////////////////////////////////////////////////////////////////////////

  // determine biofix message Below user inputs ------------------------------------
  let userInputMsg = ""
  const msg1 = gddRangeToMsg(
    userInputs.firstInput.message,
    selectedDate[`${baseFormulaBeforeBiofix1}Gdd`]
  )
  if (msg1 && msg1 !== "N/A") userInputMsg = msg1

  ////////////////////////////////////////////////////////////////////////////////////

  // User input DD Accumulation ------------------------------------------------------
  let startDate = data.dailyData[0].date.toLocaleDateString()
  let gddDisplay = selectedDate[`${baseFormulaBeforeBiofix1}Gdd`]

  if (biofix1) {
    startDate = "wild grape bloom"
    gddDisplay = selectedDate.biofix1Gdd
  }

  const accumulationDDMsg = {
    text: `Accumulated degree days ${
      biofix1 ? dashboardDisplayAfterBiofix1 : dashboardDisplayBeforeBiofix1
    } ${startDate} through ${data.dailyData[
      selectedDate.dayOfYear - 1
    ].date.toLocaleDateString()}:`,
    gdd: gddDisplay,
  }
  // console.log(accumulationDDMsg)
  // /////////////////////////////////////////////////////////////////////////////////////

  // Data to display in Results Table --------------------------------------------------
  let tableData = [...mData]
  if (mData.length >= 8) {
    tableData = mData.slice(
      selectedDate.dayOfYear - 3,
      selectedDate.dayOfYear + 5
    )
  }
  // console.log(tableData)
  // //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  let csvData = []
  csvData = mData.map((day) => {
    if (biofix1) {
      return {
        Date: day.dateDisplay,
        [`Daily DD ${dashboardDisplayBeforeBiofix1}`]: day[
          `${baseFormulaBeforeBiofix1}dd`
        ],
        "From Jan 1": day[`${baseFormulaBeforeBiofix1}Gdd`],
        [`Daily DD ${dashboardDisplayAfterBiofix1}`]: day.biofix1Gdd,
        [`From ${format(biofix1.date, "LLL d")}`]: day.biofix1Gdd,
      }
    }
    return {
      Date: day.dateDisplay,
      [`Daily DD ${dashboardDisplayBeforeBiofix1}`]: day[
        `${baseFormulaBeforeBiofix1}dd`
      ],
      "From Jan 1": day[`${baseFormulaBeforeBiofix1}Gdd`],
    }
  })

  // console.log(csvData)
  //////////////////////////////////////////////////////////////////////////////////////

  // Management Guide ------------------------------------------------------------------
  let managementGuide = {
    pestStage: "",
    pestStatus: "",
    pestManagement: "",
    dashboardDisplay: "",
  }

  const msg = gddRangeToMsg(
    beforeBiofix1,
    selectedDate[`${baseFormulaBeforeBiofix1}Gdd`]
  )
  if (msg && msg !== "N/A") managementGuide = msg

  if (biofix1) {
    const msg = gddRangeToMsg(afterBiofix1, selectedDate.biofix1Gdd)
    if (msg && msg !== "N/A") managementGuide = msg
  }

  // console.log(managementGuide)
  //////////////////////////////////////////////////////////////////////////////////////

  // Determine Phenological Stage ----------------------------------------------------
  let phenologicalStage = null
  //////////////////////////////////////////////////////////////////////////////////////

  // Determine Pest Stage -----------------------------------------------------------
  let pestStage = null
  //////////////////////////////////////////////////////////////////////////////////////

  if (!isModelInSeason) {
    managementGuide = { ...elements.managementGuide.outOfSeasonMsg }
  }

  // Dashboard Summary -----------------------------------------------------------------
  let dashboardSummary = {
    message: "",
    fromJan1: {
      text: `Accum. DD ${dashboardDisplayBeforeBiofix1} since Jan 1:`,
      gdd: "N/A",
    },
    [biofix1 ? "biofix1" : null]: {
      text: `Accum. DD ${dashboardDisplayAfterBiofix1} from wild grape bloom:`,
      gdd: "N/A",
    },
  }

  if (isModelInSeason === false) {
    dashboardSummary.message =
      elements.managementGuide.outOfSeasonMsg.dashboardDisplay
  } else {
    const currentDate = mData.find((d) => d.isForecast)
    if (currentDate) {
      dashboardSummary.message = managementGuide.dashboardDisplay
      dashboardSummary.fromJan1.gdd =
        currentDate[`${baseFormulaBeforeBiofix1}Gdd`]
      if (biofix1) {
        dashboardSummary.biofix1.gdd = currentDate.biofix1Gdd
      }
    }
  }
  // console.log(dashboardSummary)
  // //////////////////////////////////////////////////////////////////////////////////////

  return {
    data: mData,
    biofix1,
    selectedDate,
    userInputMsg,
    accumulationDDMsg,
    tableData,
    csvData,
    managementGuide,
    phenologicalStage,
    pestStage,
    dashboardSummary,
  }
}
