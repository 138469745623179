import React from "react";
import BiofixDateInput from "../../components/models/biofixDateInput";
import ManagementGuide from "../../components/models/managementGuide";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./grape-berry-moth.json";
import Acknowledgments from "./_acknowledgements";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function GrapeBerryMoth() {
  const h1Text = modelData.title;
  const { title, id, elements, seasonStart, seasonEnd } = modelData;
  const { managementGuide } = modelData.elements;
  const { isAuthenticated, user } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  const [userBiofix1, setUserBiofix1] = React.useState(null);
  const [userPhenologicalStage, setUserPhenologicalStage] =
    React.useState(null);
  const [userPestStage, setUserPestStage] = React.useState(null);

  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(
      modelData,
      data,
      dateOfInterest,
      isModelInSeason,
      userBiofix1,
      userPhenologicalStage,
      userPestStage
    );
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);

  function resetUserData() {
    setUserBiofix1(null);
    setUserPhenologicalStage(null);
    setUserPestStage(null);
  }

  React.useEffect(() => {
    resetUserData();
  }, [dateOfInterest]);

  React.useEffect(() => {
    if (user && Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === id);
      if (model) {
        // Adjust to new API -------------------------
        if (!Object.keys(model).includes("biofix1")) {
          model["biofix1"] = {};
        }
        //////////////////////////////////////////////
        if (
          Object.keys(model.biofix1).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.biofix1[dateOfInterest.year]).includes(station.id)
        ) {
          setUserBiofix1(model.biofix1[dateOfInterest.year][station.id]);
        } else {
          setUserBiofix1(null);
        }
      }
    }
  }, [station, id, user, dateOfInterest]);

  const isVisible = station && mData && !isLoading;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      tutorialLink={modelData.tutorialLink}
      h1Text={h1Text}
    >
      {/* User Input petal fall date */}
      <div className={`mb-16 sm:mb-18 md:mb-20`}>
        {isModelInSeason && isVisible && (
          <>
            <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
              {elements.userInputs.firstInput.label}
            </h2>
            <div className="px-6 py-6 bg-white rounded-lg shadow-lg">
              {mData.selectedDate.displayBiofix1Input && (
                <div className="flex items-center justify-center py-6">
                  <BiofixDateInput
                    modelId={id}
                    id={"biofix1"}
                    dbKey="biofix1"
                    minDate={new Date(`${dateOfInterest.year}-${seasonStart}`)}
                    maxDate={dateOfInterest.date}
                    biofix={mData.biofix1}
                    userBiofix={userBiofix1}
                    setUserBiofix={setUserBiofix1}
                    label={elements.userInputs.firstInput.label}
                    modelName={title}
                    tooltip={`Reset biofix to default value`}
                    datesWithNoData={[]}
                    station={station}
                  ></BiofixDateInput>
                </div>
              )}

              <div className="flex items-center justify-center">
                <div className="flex flex-col items-center py-2">
                  {mData.selectedDate.displayBiofix1Input && (
                    <p className="max-w-3xl p-2 mx-auto mb-6 text-gray-700 rounded-md bg-gray-50">
                      {mData.userInputMsg}
                    </p>
                  )}

                  <p className="max-w-3xl mx-auto">
                    {mData.accumulationDDMsg.text}{" "}
                    <b>{mData.accumulationDDMsg.gdd}</b>
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Results Table */}
      {isModelInSeason && isVisible && showResultsTable && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            tableData={mData.tableData}
            csvData={mData.csvData}
            biofix1={mData.biofix1}
          ></ResultsTable>
        </div>
      )}

      {/* Management Guide */}
      {!isLoading && mData && showManagementGuide && (
        <div className={`mb-16 sm:mb-18 md:mb-20`}>
          <h2 className="mb-3 font-semibold text-gray-600 sm:mb-5 md:mb-6 md:text-2xl">
            {managementGuide.title}
          </h2>
          <ManagementGuide
            managementGuideMsg={
              isModelInSeason
                ? mData.managementGuide
                : managementGuide.outOfSeasonMsg
            }
            setIsMoreInfo={setIsMoreInfo}
          ></ManagementGuide>
        </div>
      )}
    </ModelLayout>
  );
}
