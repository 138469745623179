import GlobalStateContext from "../../context/globalStateContext"
import parse from "html-react-parser"
import React from "react"

export default function MoreInfo({ moreInfo, station }) {
  const { DEFAULT_STATION } = React.useContext(GlobalStateContext)

  return (
    <ul className="px-4 mt-6 text-sm text-left list-disc">
      {moreInfo
        .filter(
          (d) =>
            d.statesOrProvinces.includes("ALL") ||
            d.statesOrProvinces.includes(
              station ? station.state : DEFAULT_STATION.state
            )
        )
        .map((d) => {
          return (
            <li key={d.url} className="mb-4">
              <a href={d.url} target="_blank" rel="noopener noreferrer">
                {parse(d.title)}
              </a>
            </li>
          )
        })}
    </ul>
  )
}
