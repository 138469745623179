import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Tobin, P.C., Nagarkatti, S. and Saunders, M.C. 2001. Modeling
        development in grape berry moth (Lepidoptera: Tortricidae). Environ.
        Entomol. 30:692-699.
      </p>
    </>
  )
}
