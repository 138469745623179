import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The NEWA grape berry moth degree day tool was built by Greg Loeb and
        Timothy Weigle, Cornell University, and Andy Muza, Pennsylvania State
        University.
      </p>
    </>
  )
}
